<template>
  <v-dialog v-model="show" :width="appDimensions.createGroupDialog.width" persistent>
    <template v-slot:activator="{ on, attrs }"  v-if="assignedRole.data.permissions.orgGrp.add">
      <v-btn v-bind="attrs" v-on="on" elevation="0" color="primary">
        <v-icon left> mdi-plus </v-icon>
        Add New Group
      </v-btn>
    </template>
    <v-card class="pa-0">
      <v-card-title class="justify-center">
        Add New Group
      </v-card-title>
      <v-divider class="mb-2"></v-divider>
      <v-card-text class="pa-0">
        <v-form ref="addNewGroupForm" lazy-validation>
          <v-row no-gutters>
            <v-col cols="4">
              <v-text-field v-model="group.name" label="Group Name" :rules="rules.nameRules" required class="mx-3">
              </v-text-field>
              <v-textarea outlined label="Description" @keyup.enter="addNewRole" v-model="group.description"
                class="mx-3">
              </v-textarea>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col cols="4" align="center">
              <span class="text-subtitle-1 black--text py-3"> Add Kiosks </span>
              <v-combobox v-model="group.assignedKiosks" :rules="[(v) => !!v || 'Assign at-least one kiosk']" outlined
                dense class="mx-3" hide-selected hide-details="" :items="kiosks" label="Assign Kiosks" multiple
                @change="group.assignedKiosks = []">
                <template v-slot:item="data">
                  <v-list-item-content @click="addKiosk(data.item)">
                    <v-list-item-title v-html="data.item.data().name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-combobox>
              <v-sheet min-height="10" max-height="180" class="mb-5 overflow-y-auto" width="90%"
                v-if="addedKioskList.length > 0">
                <v-list>
                  <v-list-item v-for="(kiosk, i) in addedKioskList" :key="i">
                    <v-list-item-icon>
                      <v-icon> mdi-presentation-play </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ getKioskName(kiosk) }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon @click="removeKiosk(kiosk)">
                        mdi-close
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-sheet>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col cols="4" align="center">
              <span class="text-subtitle-1 black--text my-3"> Add Users </span>
              <v-combobox v-model="group.users" :items="usersList" hide-selected multiple outlined
                @change="group.users = []" label="Add Users" hide-details="" dense class="mx-3">
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-avatar color="primary" size="36" class="white--text">
                      {{ getUserInitials(data.item.id).initial }}
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content @click="addUser(data.item)">
                    <v-list-item-title v-html="data.item.data().name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-combobox>
              <v-sheet min-height="10" max-height="180" class="mb-5 overflow-y-auto" width="90%"
                v-if="addedUsersList.length > 0">
                <v-list>
                  <v-list-item v-for="(user, i) in addedUsersList" :key="i">
                    <v-list-item-avatar color="primary" class="white--text">
                      {{ getUserInitials(user).initial }}
                      <!-- <v-icon> mdi-presentation-play </v-icon> -->
                    </v-list-item-avatar>
                    <v-list-item-content>
                      {{ getUserInitials(user).name }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon @click="removeUser(user)" small>
                        mdi-close
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" align="end">
              <v-btn width="175" class="ma-3" color="error" @click="resetForm()" outlined> Close </v-btn>
              <v-btn width="175" class="ma-3" color="primary" @click="addNewGroup()">
                <v-icon left>mdi-plus</v-icon> Add Group
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  name: "addNewGroup",
  data() {
    return {
      show: null,
      loading: false,

      addedUsersList: [],
      addedKioskList: [],

      group: {
        name: "",
        description: "",
        users: [],
        assignedKiosks: [],
      },

      rules: {
        nameRules: [(v) => !!v || "Role name is required"],
        descriptionRules: [(v) => !!v || "Description is required"],
      },
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredUsers",
      "allRegisteredKiosks",
      "userId",
      "userProfile",
      "assignedRole",
      "appDimensions"
    ]),
    usersList() {
      let user = [];
      this.allRegisteredUsers.forEach((users) => {
        user.push(users);
      });
      if (this.addedUsersList.length >= 1) {
        return user.filter((x) => {
          return !this.addedUsersList.some((y) => {
            return x.id === y;
          });
        });
      } else {
        return user;
      }
    },
    kiosks() {
      let kiosks = [];
      this.allRegisteredKiosks.forEach((kiosk) =>
        kiosks.push(kiosk)
      );
      if (this.addedKioskList.length >= 1) {
        return kiosks.filter((x) => {
          return !this.addedKioskList.some((y) => {
            return x.id === y;
          });
        });
      } else {
        return kiosks;
      }
    },
  },
  methods: {
    addNewGroup() {
      if (this.$refs.addNewGroupForm.validate()) {
        this.loading = true;
        this.$store
          .dispatch("addNewGroup", {
            name: this.group.name,
            description: this.group.description,
            users: this.addedUsersList,
            assignedKiosks: this.addedKioskList,
          })
          .then(
            (response) => {
              this.loading = false;

              this.$store.dispatch("showSnackBar", {
                text: response,
                color: "success",
              });
              this.resetForm();

              if (this.userProfile.data().userRole !== "Super Admin") {
                this.addedUsersList = [this.userId]
              } else {
                this.addedUsersList = []
              }
            },
            (error) => {
              this.$store.dispatch("showSnackBar", {
                text: error,
                color: "error",
              });
            }
          );
      }
    },
    getUserInitials(id) {
      let userInfo = this.allRegisteredUsers.find((user) => user.id === id);
      let initials = { initial: "", name: "" };
      if (userInfo.data().name !== null || userInfo.data().name !== "") {
        let split = userInfo.data().name.split(" ");
        split.forEach((item) => {
          initials.initial += item[0];
        });
        initials.initial = initials.initial.substring(0, 2);
        initials.name = userInfo.data().name;
      }
      return initials;
    },
    getKioskName(id) {
      let kioskInfo = this.allRegisteredKiosks.find(kiosk => kiosk.id === id)
      return kioskInfo.data().name
    },
    addUser(user) {
      this.addedUsersList.push(user.id);
    },
    removeUser(id) {
      this.addedUsersList.splice(this.addedUsersList.indexOf(id), 1);
    },
    addKiosk(kiosk) {
      this.addedKioskList.push(kiosk.id)
    },
    removeKiosk(id) {
      this.addedKioskList.splice(this.addedKioskList.indexOf(id), 1);
    },
    resetForm() {
      this.show = false;
      this.$refs.addNewGroupForm.reset();
      this.addedUsersList = []
      this.addedKioskList = []
    }
  },
  mounted() {
    if (this.userProfile.data().userRole !== "Super Admin") {
      this.addedUsersList.push(this.userId);
    }
  },
};
</script>